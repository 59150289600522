import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Shootings(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.panel === "panel8"}
        maxWidth={"md"}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        keepMounted
      >
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <CardMedia
          sx={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "black",
          }}
          image="/Services/shooterDark.jpg"
          title="Car Crashes"
        />
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
          }}
        >
          <Typography
            variant={fullScreen ? "h4" : "h2"}
            color={"#fad461"}
            fontWeight={"900"}
            pb={2}
          >
            Gun Violence
          </Typography>
          <Typography
            variant={fullScreen ? "h6" : "h4"}
            color={"white"}
            fontWeight={"900"}
            pb={5}
          >
            If you have been the victim of a drive by shooting or road rage
            turned violent call Law Office of David Rhee for a free
            consultation.
          </Typography>

          <Button
            href={"#Contact"}
            sx={{
              float: "right",
              fontSize: fullScreen ? "" : "32px",
              color: "#FAD461",
            }}
            autoFocus
            onClick={props.handleClose}
          >
            Contact Us
          </Button>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
