import React from "react";
import { Button, styled } from "@mui/material";
import {
  Paper,
  Grid,
  Typography,
  CardActionArea,
  ButtonBase,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import { Theme } from "@mui/material";

// const useStyles = styled(Paper)(({ theme }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(2),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   height: "100%",
// }));

const services = [
  {
    id: 1,
    name: "Car Accidents",
    description:
      "When you are injured in a car accident, recovering from your injuries should be your only priority. Let the Law Office of David Rhee deal with the insurance companies and the claims process. David Rhee is committed to maximizing your recovery for injuries sustained in car crashes.",
    image: "/Services/carCrash.png",
    panel: "panel1",
  },
  {
    id: 2,
    name: "DWI Crashes",
    description:
      "Sadly, DWI crashes are all too common in New Mexico. If you or a family member has been the victim of a DWI crash, you will need competent representation. David Rhee uses his knowledge of the law to maximize recovery for victims of DWI crashes. ",
    image: "/Services/dui.png",
    panel: "panel2",
  },
  {
    id: 3,
    name: "18-Wheeler Crashes",
    description:
      "When driven negligently, tractor trailers can cause devastating injures. Law Office of David Rhee knows how to hold trucking companies and their drivers accountable for all kinds of trucking accidents.",
    image: "/Services/eighteenWheeler.png",
    panel: "panel3",
  },
  {
    id: 4,
    name: "Wrongful Death",
    description:
      "Nothing can bring back a loved one who has been lost to tragedy. However, when negligent actors are held accountable for causing the death of a loved one, it helps to ensure that the same conduct does not cost the life of another in the future. ",
    image: "/Services/death.png",
    panel: "panel4",
  },
  {
    id: 5,
    name: "Slip and Fall",
    description:
      "Fall hazards happen when stores or companies fail to fix, or clean, hazardous conditions on their premises. Sadly, falls can cause serious injures such as fractures, ligament tears, and head injuries. If you suffer injury after falling you will need an experienced law firm to represent you. ",
    image: "/Services/slipFall.png",
    panel: "panel5",
  },
  {
    id: 6,
    name: "Dog Bites",
    description:
      "Dog bites can cause serious puncture wounds or tear injuries. Dog bites can also cause permanent scarring. To get the full value of your dog bite case, call Law Office of David Rhee.",
    image: "/Services/dogBites.png",
    panel: "panel6",
  },
  {
    id: 7,
    name: "Medical Malpractice",
    description:
      "We trust doctors and medical professionals to take care of each and every patient they care for. Unfortunately, sometimes medical professionals make serious mistakes. If medical negligence has left you with injuries, you need a lawyer who can hold the wrongdoers to account. ",
    image: "/Services/medMal.png",
    panel: "panel7",
  },
  {
    id: 8,
    name: "Gun Violence",
    description:
      "If you have been the victim of a drive by shooting or road rage turned violent call Law Office of David Rhee for a free consultation. ",
    image: "/Services/carCrash.png",
    panel: "panel8",
  },
];

const images = services.map((service) => (new Image().src = service.image));

export default function ServiceTitles(props) {
  return (
    <Grid
      container
      direction={"column"}
      sx={{
        justifyContent: "end",
        alignItems: "center",
      }}
    >
      {services.map((service) => (
        <div style={{ position: "relative" }}>
          <ButtonBase
            disableRipple={true}
            onClick={() => props.handleClickOpen(service.panel)}
            sx={{
              pb: 3,
              border: "none !important",
              boxShadow: "none",
              flexShrink: 0,
              // left: "200px",
              color: "white",
              fontSize: "white",
              transform: "scale(1)",
              transition:
                "color 0.3s ease-in-out, font-size 0.3s ease-in-out, transform 0.3s ease-in-out",
              zIndex: "inherit",
              cursor: "pointer",
              "&:hover": {
                color: "#FAD461",
                fontSize: "52px",
                transform: "scale(1.2)",
                transition:
                  "color 0.3s ease-in-out, font-size 0.3s ease-in-out, transform 0.3s ease-in-out",
                zIndex: 1,
              },
              // "&:focus": {
              //   color: "#FAD461",
              //   fontSize: "52px",
              //   transform: "scale(1.2)",
              //   transition:
              //     "color 0.3s ease-in-out, font-size 0.3s ease-in-out, transform 0.3s ease-in-out",
              //   zIndex: 1,
              // },
            }}
          >
            <Typography variant={"h4"} fontWeight={"900"}>
              {service.name}
            </Typography>
          </ButtonBase>
        </div>
      ))}
    </Grid>
  );
}
