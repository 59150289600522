import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Avatar from "@mui/material/Avatar";
import AboutMD from "../components/About/AboutMD";
import AboutSM from "../components/About/AboutSM";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const image = (new Image().src = "/ziaDark.png");

const About = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return matches ? <AboutMD /> : <AboutSM />;
};

export default About;
