import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Paper, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";

const image = "/abqDarkSmall.jpg";

const HeroPaper = styled(Paper)(({ theme, loaded }) => ({
  minHeight: "100vh",
  height: "100vh",
  // backgroundImage: `url(${image})`,
  backgroundImage: loaded ? `url(${image})` : "none",
  backgroundColor: loaded ? "transparent" : "#3F4050",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    height: "60vh",
  },
}));

const LogoImg = styled("img")(({ theme }) => ({
  height: 265,
  width: "auto",
  marginRight: theme.spacing(2),
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#fff",
  marginBottom: theme.spacing(4),
  textAlign: "left",
  fontSize: "4rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "3rem",
  },
  animation: `${keyframes({
    "0%": { transform: "translateX(1rem)", opacity: 0 },
    "100%": { transform: "translateX(0)", opacity: 1 },
  })} 2.2s ease-out`,
}));

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  color: "#fff",
  // marginBottom: theme.spacing(0.5),
  textAlign: "center",
  fontSize: "2rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  animation: `${keyframes({
    "0%": { transform: "translateX(1rem)", opacity: 0 },
    "100%": { transform: "translateX(0)", opacity: 1 },
  })} 4s ease-out`,
}));

const Placeholder = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  height: "100vh",
  backgroundColor: "#3F4050",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Hero = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = image;
  }, []);

  return (
    <>
      {!loaded && <Placeholder />}
      <HeroPaper loaded={loaded}>
        <LogoImg src="/drLogoPng.png" alt="Logo" />
        <div>
          <TitleTypography variant="h2">
            Law Office of David Rhee
          </TitleTypography>
          <SubtitleTypography variant="subtitle1">
            When Justice Matters
          </SubtitleTypography>
          <SubtitleTypography variant="subtitle1">
            Results Follow
          </SubtitleTypography>
        </div>
      </HeroPaper>
    </>
  );
};

export default Hero;
