import { Grid, Paper, Typography } from "@mui/material";
import NavBar from "./components/Nav/NavBar";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import theme from "./Theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import Contact from "./Pages/Contact";
import Footer from "./Pages/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";

function App() {
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{ mr: xl ? 40 : 0, ml: xl ? 40 : 0 }}>
        <NavBar />
        <Home />
        <Contact />
        <Services />
        <About />
        <Footer />
      </Paper>
    </ThemeProvider>
  );
}

export default App;
