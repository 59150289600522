import { Grid, Paper, Stack, Typography, IconButton } from "@mui/material";
import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MapIcon from "@mui/icons-material/Map";
import InstagramIcon from "@mui/icons-material/Instagram";
const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      height={"10em"}
      width={"100%"}
      pl={1}
      pt={1}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      sx={{
        backgroundColor: "#C3BEC6",
      }}
    >
      <Grid
        container
        alignItems={"left"}
        justifyContent={"flex-start"}
        direction={"column"}
      >
        <Stack spacing={2}>
          <Stack direction={"row"} alignItems={"left"}>
            <Typography variant={matches ? "subtitle1" : "caption"}>
              Copyright
            </Typography>
            <CopyrightIcon sx={{ pr: 1 }} fontSize={matches ? "small" : "xs"} />
            <Typography
              sx={{ pr: 1 }}
              variant={matches ? "subtitle1" : "caption"}
            >
              2024 Law Office of David Rhee. All rights reserved
            </Typography>
          </Stack>
          <Typography variant={matches ? "subtitle1" : "caption"}>
            This website is attorney advertising and is not meant as legal
            advice.
          </Typography>
          <Stack color={"#fff"} direction={"row"}>
            <IconButton
              edge="start"
              size={"large"}
              href={"https://www.instagram.com/davidrheelaw/"}
              target="_blank"
              sx={{
                zIndex: 100,
                // color: "",
              }}
            >
              <InstagramIcon></InstagramIcon>
            </IconButton>
            <IconButton
              size={"large"}
              href={"https://maps.app.goo.gl/LwF9ukaVNKeSaaET9"}
              target="_blank"
              sx={{
                zIndex: 100,
                // color: "#fff",
              }}
            >
              <MapIcon></MapIcon>
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
