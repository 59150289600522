import { Grid, Typography, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import ContactForm from "./ContactForm";
import MapIcon from "@mui/icons-material/Map";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import MiniLogo from "./miniLogo";
import ContactPopup from "./Dialog/ContactPopup";

const ContactPageMD = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        id={"Contact"}
        container
        item
        // spacing={15}
        // height={"100%"}
        sx={{
          // padding: matches ? 10 : 2,
          // overflow: "auto",
          height: "fit-content",
          backgroundColor: "#FAD461",
        }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            padding: matches ? 10 : 2,
          }}
        >
          <Typography
            textAlign={"left"}
            variant={matches ? "h2" : "h3"}
            fontWeight={900}
            color={"#BB0A21"}
            pb={2}
          >
            Free Consultations
          </Typography>
          <Stack direction={"row"}>
            <Typography variant={"subtitle2"} pr={1}>
              Don't navigate the legal system without proper guidance.
            </Typography>
            <Typography variant={"subtitle2"}>Hablamos Español.</Typography>
          </Stack>

          <ContactForm setOpen={setOpen} />
        </Grid>
        {/**Contact and Address info Start */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            pt: 0,
            pb: 5,
            backgroundColor: "#3F4050",
          }}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction="column"
            pt={5}
            sx={{ mt: { xs: 10, md: 0 } }}
          >
            <Typography
              variant={"h5"}
              // fontWeight={900}
              color={"#FAD461"}
            >
              Law Office of David Rhee
            </Typography>
            {matches ? (
              <Stack
                justifyContent={"center"}
                color={"#fff"}
                alignItems={"center"}
              >
                <Typography pb={1} variant={"h6"}>
                  When Justice Matters
                </Typography>
                <Typography pb={5} variant={"h6"}>
                  Results Follow
                </Typography>
              </Stack>
            ) : null}
            <Typography color={"#fff"} variant={"h6"} whiteSpace={"noWrap"}>
              300 Central Ave. SW Suite 3000
            </Typography>
            <Typography color={"#fff"} variant={"h6"} whiteSpace={"noWrap"}>
              Albuqueruque, New Mexico 87102
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <Stack direction={"row"}>
              <Typography variant={"h6"} color={"#FAD461"} pt={5} pr={1}>
                T:
              </Typography>
              <a
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  zIndex: 100,
                }}
                href="tel:+1-505-589-7008"
              >
                <Typography color={"#fff"} variant={"h6"} pt={5}>
                  (505) 589 - 7008
                </Typography>
              </a>
            </Stack>
            <Stack direction={"row"}>
              <Typography variant={"h6"} color={"#FAD461"} pr={1}>
                F:
              </Typography>
              <Typography color={"#fff"} variant={"h6"}>
                (505) 273 - 8990
              </Typography>
            </Stack>
            <Stack color={"#fff"} pt={1} direction={"row"}>
              <IconButton
                edge="start"
                size={"large"}
                href={"https://www.instagram.com/davidrheelaw/"}
                target="_blank"
                sx={{
                  zIndex: 100,
                  color: "#fff",
                  "&:hover": {
                    // backgroundColor: "#FAD461",
                    color: "#BB0A21",
                  },
                }}
              >
                <InstagramIcon></InstagramIcon>
              </IconButton>
              <IconButton
                size={"large"}
                href={"https://maps.app.goo.gl/LwF9ukaVNKeSaaET9"}
                target="_blank"
                sx={{
                  zIndex: 100,
                  color: "#fff",
                  "&:hover": {
                    // backgroundColor: "#FAD461",
                    color: "#BB0A21",
                  },
                }}
              >
                <MapIcon></MapIcon>
              </IconButton>
            </Stack>
            {matches ? null : (
              <Stack
                justifyContent={"center"}
                color={"#fff"}
                alignItems={"center"}
              >
                <Typography pt={12} variant={"subtitle1"}>
                  When Justice Matters
                </Typography>
                <Typography variant={"subtitle1"}>Results Follow</Typography>
              </Stack>
            )}

            <Grid item sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  bottom: matches ? -340 : -190,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <MiniLogo />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ContactPopup
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default ContactPageMD;
