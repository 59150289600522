import React from "react";
import { Button, styled } from "@mui/material";
import {
  Paper,
  Grid,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import { Theme } from "@mui/material";

// const useStyles = styled(Paper)(({ theme }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(2),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   height: "100%",
// }));

const services = [
  {
    id: 1,
    name: "Car Accidents",
    description:
      "When you are injured in a car accident, recovering from your injuries should be your only priority. Let the Law Office of David Rhee deal with the insurance companies and the claims process. David Rhee is committed to maximizing your recovery for injuries sustained in car crashes.",
    image: "/Services/carCrash.png",
    panel: "panel1",
  },
  {
    id: 2,
    name: "DWI Crashes",
    description:
      "Sadly, DWI crashes are all too common in New Mexico. If you or a family member has been the victim of a DWI crash, you will need competent representation. David Rhee uses his knowledge of the law to maximize recovery for victims of DWI crashes. ",
    image: "/Services/dui.png",
    panel: "panel3",
  },
  {
    id: 3,
    name: "18-Wheeler Crashes",
    description:
      "When driven negligently, tractor trailers can cause devastating injures. Law Office of David Rhee knows how to hold trucking companies and their drivers accountable for all kinds of trucking accidents.",
    image: "/Services/eighteenWheeler.png",
    panel: "panel4",
  },
  {
    id: 4,
    name: "Wrongful Death",
    description:
      "Nothing can bring back a loved one who has been lost to tragedy. However, when negligent actors are held accountable for causing the death of a loved one, it helps to ensure that the same conduct does not cost the life of another in the future. ",
    image: "/Services/death.png",
    panel: "panel5",
  },
  {
    id: 5,
    name: "Slip and Fall",
    description:
      "Fall hazards happen when stores or companies fail to fix, or clean, hazardous conditions on their premises. Sadly, falls can cause serious injures such as fractures, ligament tears, and head injuries. If you suffer injury after falling you will need an experienced law firm to represent you. ",
    image: "/Services/slipFall.png",
    panel: "panel6",
  },
  {
    id: 6,
    name: "Dog Bites",
    description:
      "Dog bites can cause serious puncture wounds or tear injuries. Dog bites can also cause permanent scarring. To get the full value of your dog bite case, call Law Office of David Rhee.",
    image: "/Services/dogBites.png",
    panel: "panel7",
  },
  {
    id: 7,
    name: "Medical Malpractice",
    description:
      "We trust doctors and medical professionals to take care of each and every patient they care for. Unfortunately, sometimes medical professionals make serious mistakes. If medical negligence has left you with injuries, you need a lawyer who can hold the wrongdoers to account. ",
    image: "/Services/medMal.png",
    panel: "panel8",
  },
  {
    id: 8,
    name: "Drive by Shootings",
    description:
      "If you have been the victim of a drive by shooting or road rage turned violent call Law Office of David Rhee for a free consultation. ",
    image: "/Services/carCrash.png",
    panel: "panel9",
  },
];

const images = services.map((service) => (new Image().src = service.image));

export default function EachService() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid container direction={"column"} sx={{ overflow: "scroll" }}>
      <Grid item xs={12} sm={4}>
        {services.map((service) => (
          <Accordion
            key={service.id}
            expanded={expanded === service.panel}
            onChange={handleChange(service.panel)}
            TransitionProps={{ unmountOnExit: true }}
            elevation={0}
            sx={{
              border: "none !important",
              width: "auto",
              boxShadow: "none",
              backgroundColor: "transparent !important",
              color: "white ",
              fontWeight: "900",
              margin: 0,
              height: "auto",
              // overflow: "hidden",
            }}
          >
            <AccordionSummary
              expandIcon={
                // <ExpandCircleDownIcon color="white" fontSize="large" />
                null
              }
              aria-controls="panel1bh-content"
              id={`panel${service.id}bh-header`}
              sx={{
                border: "none !important",
                boxShadow: "none",
                flexShrink: 0,
                left: "100px",
                color: "white",
                fontSize: "white",
                transform: "scale(1)",
                transition:
                  "color 0.3s ease-in-out, font-size 0.3s ease-in-out, transform 0.3s ease-in-out",
                zIndex: "inherit",
                "&:hover": {
                  color: "#FAD461",
                  fontSize: "52px",
                  transform: "scale(1.2)",
                  transition:
                    "color 0.3s ease-in-out, font-size 0.3s ease-in-out, transform 0.3s ease-in-out",
                  zIndex: 1,
                },
                "&:focus": {
                  color: "#FAD461",
                  fontSize: "52px",
                  transform: "scale(1.2)",
                  transition:
                    "color 0.3s ease-in-out, font-size 0.3s ease-in-out, transform 0.3s ease-in-out",
                  zIndex: 1,
                },
              }}
            >
              <Typography variant={"h4"} fontWeight={"900"}>
                {service.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                border: "none",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              {/* <Typography>{service.description}</Typography> */}
              <Card>
                {/* <CardActionArea> */}
                <CardMedia
                  component="img"
                  height="200"
                  image={service.image}
                  alt={"Practice Area"}
                  loading={"eager"}
                />

                <CardContent
                  sx={{
                    // borderBottom: "solid black 1px",
                    backgroundColor: "white ",
                    color: "white",
                    fontWeight: "900",
                  }}
                >
                  <Typography variant="body1" color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
                {/* </CardActionArea> */}
                <CardActions sx={{ backgroundColor: "white" }}>
                  <Button size="small" color="primary" href={"#Contact"}>
                    Contact Us
                  </Button>
                </CardActions>
              </Card>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
}
