import React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";

const image = "/abqDarkSmall.jpg";

const HeroPaper = styled(Paper)(({ theme }) => ({
  // minHeight: "100vh",
  height: "500px",
  backgroundImage: `url(${image})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
}));

const LogoImg = styled("img")(({ theme }) => ({
  height: "auto",
  width: "100%",
  marginRight: theme.spacing(2),
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#fff",
  marginBottom: theme.spacing(4),
  textAlign: "center",
  fontSize: "5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "3rem",
  },
  animation: `${keyframes({
    "0%": { transform: "translateY(1rem)", opacity: 0 },
    "100%": { transform: "translateY(0)", opacity: 1 },
  })} 2.2s ease-out`,
}));

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  color: "#fff",
  marginBottom: theme.spacing(0.5),
  textAlign: "center",
  fontSize: "3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  animation: `${keyframes({
    "0%": { transform: "translateY(1rem)", opacity: 0 },
    "100%": { transform: "translateY(0)", opacity: 1 },
  })} 3s ease-out`,
}));

const HeroMobile = () => {
  return (
    <HeroPaper>
      <LogoImg src={"./drLogoPng.png"} alt="Logo" />
      <div>
        {/* <TitleTypography variant="h2">Law Office of David Rhee</TitleTypography> */}
        <SubtitleTypography variant="subtitle1">
          When Justice Matters
        </SubtitleTypography>
        <SubtitleTypography variant="subtitle1">
          Results Follow
        </SubtitleTypography>
      </div>
    </HeroPaper>
  );
};

export default HeroMobile;
