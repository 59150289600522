import { Grid, Paper, Typography, Box } from "@mui/material";
import React from "react";

import EachService from "../components/Services/EachService";
import ServiceTitles from "../components/Services/ServiceTitles";
import CustomizedDialogs from "../components/Services/EachServiceDialog";
import ServicesMD from "../components/Services/ServicesMD";
import ServicesSM from "../components/Services/ServicesSM";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const image = (new Image().src = "/sandia2Dark.png");

const Services = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState("");

  const handleClickOpen = (panel) => {
    setOpen(panel);
  };

  const handleClose = (panel) => {
    setOpen(panel);
  };

  return matches ? <ServicesMD /> : <ServicesSM />;
};

export default Services;
