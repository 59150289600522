import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import CardMedia from "@mui/material/CardMedia";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";

import { Grid, Typography, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { Box, Stack } from "@mui/system";
import MapIcon from "@mui/icons-material/Map";
import InstagramIcon from "@mui/icons-material/Instagram";

import MiniLogo from "../miniLogo";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ContactPopup(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth={"md"}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        keepMounted
      >
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid
          sx={{
            pt: !matches ? 0 : 10,
            pr: !matches ? 0 : 10,
            pb: !matches ? 0 : 15,
            pl: !matches ? 0 : 10,
            overflow: "hidden",
          }}
        >
          <Grid item xs={12} md={6}>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              direction="column"
              sx={{ mt: { xs: 10, md: 0 } }}
            >
              <Typography
                variant={matches ? "h4" : "h5"}
                fontWeight={800}
                color={"#BB0A21"}
              >
                Law Office of David Rhee
              </Typography>
              {matches ? (
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <Typography pb={1} variant={"h5"}>
                    When Justice Matters
                  </Typography>
                  <Typography pb={5} variant={"h5"}>
                    Results Follow
                  </Typography>
                </Stack>
              ) : null}
              <Typography variant={"h6"} textAlign={"center"} p={5}>
                Thank you for contacting us. We will respond promptly to your
                message. Please feel free to call our office anytime for further
                assistance.
              </Typography>
              <Typography variant={"h6"} whiteSpace={"noWrap"}>
                300 Central Ave. SW Suite 3000
              </Typography>
              <Typography variant={"h6"} whiteSpace={"noWrap"}>
                Albuqueruque, New Mexico 87102
              </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
            >
              <Stack direction={"row"}>
                <Typography variant={"h6"} color={"#BB0A21"} pt={5} pr={1}>
                  T:
                </Typography>
                <a
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    zIndex: 100,
                  }}
                  href="tel:+1-505-589-7008"
                >
                  <Typography variant={"h6"} pt={5}>
                    (505) 589 - 7008
                  </Typography>
                </a>
              </Stack>
              <Stack direction={"row"}>
                <Typography variant={"h6"} color={"#BB0A21"} pr={1}>
                  F:
                </Typography>
                <Typography variant={"h6"}>(505) 273 - 8990</Typography>
              </Stack>
              <Stack pt={1} direction={"row"}>
                <IconButton
                  edge="start"
                  size={"large"}
                  href={"https://www.instagram.com/davidrheelaw/"}
                  target="_blank"
                  sx={{
                    zIndex: 100,
                    "&:hover": {
                      color: "#BB0A21",
                    },
                  }}
                >
                  <InstagramIcon></InstagramIcon>
                </IconButton>
                <IconButton
                  size={"large"}
                  href={"https://goo.gl/maps/PX1mtVsf7pzFZngG9"}
                  target="_blank"
                  sx={{
                    zIndex: 100,
                    "&:hover": {
                      color: "#BB0A21",
                    },
                  }}
                >
                  <MapIcon></MapIcon>
                </IconButton>
              </Stack>
              {matches ? null : (
                <Typography pt={15} variant={"subtitle1"}>
                  When Justice Matters, Results Follow
                </Typography>
              )}

              <Grid item sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: matches ? -340 : -200,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <MiniLogo />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
