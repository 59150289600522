import { Grid, Paper, Typography, Box } from "@mui/material";
import React from "react";
const image = (new Image().src = "/ziaDark.png");

const AboutSM = () => {
  return (
    <>
      <Grid
        item
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ backgroundColor: "#FAD461" }}
        p={1}
        xs={12}
        lg={6}
      >
        <Grid
          // elevation={4}
          component="img"
          sx={{
            height: "450px",
            width: "auto",

            backgroundColor: "transparent",
          }}
          alt="David Rhee."
          src="/daveProfileNoBackground.png"
        />
      </Grid>
      <Grid
        id={"About"}
        item
        container
        xs={12}
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${image})`,
          width: "auto",
          // overflow: "auto",
          maxWidth: "xl",
          // maxHeight: "100vh",
          height: "fit-content",
          // height: "content",
          padding: 1,
        }}
      >
        <Box sx={{ maxWidth: "900px", padding: "30px", color: "#fff" }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ fontWeight: 900 }}>
                David Rhee
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: "20px" }}>
                is a dedicated attorney who is passionate about serving the New
                Mexico community. Born and raised in Albuquerque, David
                graduated from Albuquerque High School before earning his
                bachelor's degree from The University of New Mexico. He went on
                to obtain his Juris Doctorate degree from The University of New
                Mexico School of Law.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "20px" }}>
                As a fluent Spanish speaker and conversational Portuguese
                speaker, David is uniquely equipped to serve the needs of
                Spanish-speaking and immigrant communities. He is also a family
                man and devout Christian. When he's not working hard for his
                clients, David enjoys spending time with his wife and two sons,
                as well as hiking, biking, snowboarding, and exploring the great
                outdoors.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
          lg={6}
          direction="column"
        > */}
        {/* <Grid item>
            <Typography
              variant="h3"
              textAlign={"center"}
              pb={2}
              color={"#FAD461"}
              sx={{
                fontFamily: "'Roboto', sans-serif",
                padding: "0px 10px 10px 10px",
                margin: 0,
                fontWeight: 900,
              }}
            >
              David Rhee
            </Typography>
            <Typography
              variant="h5"
              textAlign={"center"}
              pb={2}
              color={"white"}
              sx={{
                padding: "0px 10px 10px 10px",
                margin: 0,
                fontWeight: 900,
              }}
            >
              is a dedicated attorney who is passionate about serving the New
              Mexico community. Born and raised in Albuquerque, David graduated
              from Albuquerque High School before earning his bachelor's degree
              from The University of New Mexico. He went on to obtain his Juris
              Doctorate degree from The University of New Mexico School of Law.
            </Typography>
            <Typography
              textAlign={"center"}
              variant={"h5"}
              pb={2}
              color={"white"}
              sx={{
                padding: "10px",
                margin: 0,
                fontWeight: 900,
              }}
            >
              As a fluent Spanish speaker and conversational Portuguese speaker,
              David is uniquely equipped to serve the needs of Spanish-speaking
              and immigrant communities. He is also a devoted family man and
              devout Christian. When he's not working hard for his clients,
              David enjoys spending time with his wife and two sons, as well as
              hiking, biking, snowboarding, and exploring the great outdoors.
            </Typography>
          </Grid> */}
        {/* </Grid> */}
      </Grid>
    </>
  );
};

export default AboutSM;
