import { Grid, Paper, Typography, Box, styled } from "@mui/material";
import React from "react";
import { keyframes } from "@emotion/react";

const image = (new Image().src = "/ziaDark.png");
const ProfileImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "relative",
  zIndex: 1,
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    background: `linear-gradient(to bottom, rgba(0,0,0,0), black)`,
    zIndex: -1,
    opacity: 10, // adjust the opacity of the gradient overlay here
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#fff",
  // marginBottom: theme.spacing(4),
  textAlign: "left",
  fontSize: "3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "3rem",
  },
  animation: `${keyframes({
    "0%": { transform: "translateX(1rem)", opacity: 0 },
    "100%": { transform: "translateX(0)", opacity: 1 },
  })} 2.2s ease-out`,
}));
const AboutMD = () => {
  return (
    <Box
      id={"About"}
      sx={{
        backgroundImage: `url(${image})`,
        // backgroundSize: "cover",
        backgroundPosition: "center",
        // minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        color: "#fff",
        fontFamily: "sans-serif",
      }}
    >
      <Box
        sx={{
          maxWidth: "900px",
          padding: "50px",
          paddingBottom: 0,
        }}
      >
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              {" "}
              {/* Center contents vertically */}
              <ProfileImage
                alt="David Rhee."
                src="/daveProfileNoBackground.png"
              ></ProfileImage>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={{ paddingBottom: 5 }}
            >
              <TitleTypography>David Rhee</TitleTypography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "20px", fontSize: "18px" }}
              >
                is a dedicated attorney who is passionate about serving the New
                Mexico community. Born and raised in Albuquerque, David
                graduated from Albuquerque High School before earning his
                bachelor's degree from The University of New Mexico. He went on
                to obtain his Juris Doctorate degree from The University of New
                Mexico School of Law. As a fluent Spanish speaker and
                conversational Portuguese speaker, David is uniquely equipped to
                serve the needs of Spanish-speaking and immigrant communities.
                He is also a family man and devout Christian. When he's not
                working hard for his clients, David enjoys spending time with
                his wife and two sons, as well as hiking, biking, snowboarding,
                and exploring the great outdoors.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutMD;
