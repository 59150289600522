import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CardMedia from "@mui/material/CardMedia";
import DrLawLogo from "../components/Home/DrLawLogo";
import TestLogo from "../components/Home/TestLogo";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Hero from "./HomeAI";
import HeroMobile from "./HomeAIMobile";

const image = (new Image().src = "/abqDark.png");

const Home = () => {
  const [imgSrc, setImgSrc] = useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  // useEffect(() => {
  //   const image = new Image();
  //   image.src = "/abqDark.png";
  //   image.onload = () => setImgSrc(image.src);
  // }, []);

  return matches ? <Hero /> : <HeroMobile />;

  // <Paper
  //   style={{
  //     minHeight: "100vh",
  //     height: "100vh",
  //     backgroundImage: `url(${image})`,
  //     // backgroundSize: "cover",
  //     backgroundPosition: "center",
  //     backgroundRepeat: "no-repeat",
  //     display: "flex",
  //     alignItems: "center",
  //     // [theme.breakpoints.down("sm")]: {
  //     //   height: "60vh",
  //     // },
  //   }}
  // >
  //   {/* {matches ? <TestLogo /> : <DrLawLogo />} */}
  //   <TestLogo />
  // </Paper>
};

export default Home;
