import { Grid, Typography, styled } from "@mui/material";
import React from "react";
import { keyframes } from "@emotion/react";
import EachService from "./EachService";
import ServiceTitles from "./ServiceTitles";
import CustomizedDialogs from "./EachServiceDialog";

const image = "/sandia2Dark.png";
const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#fff",
  // marginBottom: theme.spacing(4),
  textAlign: "left",
  fontSize: "3rem",
  whiteSpace: "nowrap",
  [theme.breakpoints.down("sm")]: {
    fontSize: "3rem",
  },
  animation: `${keyframes({
    "0%": { transform: "translateX(1rem)", opacity: 0 },
    "100%": { transform: "translateX(0)", opacity: 1 },
  })} 2.2s ease-out`,
}));
const ServicesMD = () => {
  const [open, setOpen] = React.useState("");

  const handleClickOpen = (panel) => {
    setOpen(panel);
  };

  const handleClose = (panel) => {
    setOpen("");
  };

  return (
    <Grid
      id="Practice"
      container
      sx={{
        height: "fit-content",
        padding: 10,
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
        overflow: "auto",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <TitleTypography>Law Office of David Rhee</TitleTypography>
        <Typography variant="body1" gutterBottom sx={{ color: "white" }}>
          We provide high-quality legal services to the people of New Mexico.
          Our firm is dedicated to serving clients who have suffered injury due
          to the negligence or wrongful acts of others. Our team handles a wide
          range of injury cases, from car crashes to wrongful death, and we are
          committed to achieving the best possible outcome for our clients.
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: "white" }}>
          As a bilingual firm, we are proud to serve clients who speak both
          English and Spanish. We understand the importance of clear
          communication, and we strive to ensure that our clients understand
          their legal options every step of the way. Don't navigate the legal
          system alone; call us today for a free injury case consultation.
        </Typography>
        <Typography variant="body1" sx={{ color: "white" }}>
          Our firm is proud to represent clients in a variety of practice areas,
          including:
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6}>
        <ServiceTitles
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
        />
      </Grid>
      <CustomizedDialogs
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
      />
    </Grid>
  );
};

export default ServicesMD;
