import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: '#00bcd4',
  //   },
  //   secondary: {
  //     main: '#ff5722',
  //   },
  // },
  typography: {
    fontFamily: "'Heebo', serif, Arial, sans-serif",
    body1: {
      fontFamily: "'Heebo', sans-serif",
      fontSize: "22px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 2000,
    },
  },
  components: {
    // Name of the component

    MuiPaper: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "#C3BEC6",
          overflow: "hidden",
          // maxWidth: "md",
          borderRadius: "0",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#BB0A21",
            },
            "&:hover fieldset": {
              borderColor: "#BB0A21",
            },
          },
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#BB0A21",
          },
        },
      },
    },
  },
  // overrides: {
  //   MuiPaper: {
  //     root: {
  //       backgroundColor: '#f5f5f5',
  //     },
  //   },
  //   MuiBox: {
  //     root: {
  //       backgroundColor: '#f5f5f5',
  //     },
  //   },
  // },
});

export default theme;
