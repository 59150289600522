import { Grid, Paper, Typography, Box, styled } from "@mui/material";
import React from "react";

import EachService from "./EachService";
import ServiceTitles from "./ServiceTitles";
import CustomizedDialogs from "./EachServiceDialog";

const image = (new Image().src = "/sandia2Dark.png");
const image2 = (new Image().src = "/balloonsDark.jpg");
const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#fff",
  // marginBottom: theme.spacing(4),
  textAlign: "left",
  fontSize: "1rem",
  // whiteSpace: "nowrap",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

const ServicesSM = () => {
  const [open, setOpen] = React.useState("");

  const handleClickOpen = (panel) => {
    setOpen(panel);
  };

  const handleClose = (panel) => {
    setOpen(panel);
  };

  return (
    <>
      {/* <Grid sx={{ backgroundColor: "#3F4050", padding: 1 }}></Grid> */}
      <Grid
        id={"Practice"}
        item
        container
        xs={12}
        sx={{
          // height: "100vh",
          height: "fit-content",
          padding: 2,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#3F4050",
          // backgroundImage: `url(${image2})`,
          // position: "relative",
          // overflow: "auto",
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={6}
          pt={5}
          pb={5}
          sx={{ borderTop: "solid black 1px" }}
        >
          <Grid item container>
            <Grid item>
              <TitleTypography>Law Office Of David Rhee</TitleTypography>
              {/* <TitleTypography>Law Office</TitleTypography>
              <TitleTypography>Of David Rhee</TitleTypography> */}
              <Typography
                textAlign={"left"}
                variant={"body1"}
                pb={2}
                color={"white"}
                sx={{
                  padding: "0px 10px 10px 5px",
                  margin: 0,
                }}
              >
                We provide high-quality legal services to the people of New
                Mexico. Our firm is dedicated to serving clients who have
                suffered injury due to the negligence or wrongful acts of
                others. Our team handles a wide range of injury cases, from car
                crashes to wrongful death, and we are committed to achieving the
                best possible outcome for our clients.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                textAlign={"left"}
                variant={"body1"}
                pb={2}
                color={"white"}
                sx={{
                  padding: "0px 10px 10px 10px",
                  margin: 0,
                  // fontWeight: 900,
                }}
              >
                As a bilingual firm, we are proud to serve clients who speak
                both English and Spanish. We understand the importance of clear
                communication, and we strive to ensure that our clients
                understand their legal options every step of the way.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                textAlign={"left"}
                variant={"body1"}
                pb={2}
                color={"white"}
                sx={{
                  padding: "0px 10px 45px 10px",
                  margin: 0,
                  borderBottom: "solid black 1px",
                }}
              >
                Our firm is proud to represent clients in a variety of practice
                areas
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        id={"Practice"}
        item
        container
        xs={12}
        sx={{
          // height: "100vh",
          minHeight: "fit-content",
          padding: 10,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${image})`,
          position: "relative",
          overflow: "auto",
        }}
      >
        <Grid container item xs={12} md={6}>
          <ServiceTitles
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            open={open}
          />
        </Grid>
        <CustomizedDialogs
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
        />
      </Grid>
    </>
  );
};

export default ServicesSM;
