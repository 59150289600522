import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { TextField, Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const useStyles = styled((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    backgroundColor: "#d3d4d9",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function ContactForm(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  //  Encode data for Netlify url request
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  function handleSubmit(e) {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message, phone }),
    })
      .then(() => {
        props.setOpen(true);
      })
      .catch((error) => alert(error));
  }

  return (
    <form
      className={classes.container}
      noValidate
      autoComplete="off"
      netlify={"true"}
      name="contact"
      onSubmit={handleSubmit}
    >
      <Grid container direction={matches ? "row" : "column"} spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="name"
            label="Name"
            sx={{
              width: "100%",
              backgroundColor: "#d3d4d9",
              borderRadius: "0px",
              ".MuiInputBase-root": {
                borderRadius: "0px",
              },
            }}
            onChange={(e) => setName(e.target.value)}
            className={classes.textField}
            margin="normal"
            // required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="email"
            label="Email"
            sx={{
              width: "100%",
              backgroundColor: "#d3d4d9",
              borderRadius: "0px",
              ".MuiInputBase-root": {
                borderRadius: "0px",
              },
            }}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.textField}
            margin="normal"
            // required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="phone"
            label="Phone"
            sx={{
              width: "100%",
              backgroundColor: "#d3d4d9",
              borderRadius: "0px",
              ".MuiInputBase-root": {
                borderRadius: "0px",
              },
            }}
            onChange={(e) => setPhone(e.target.value)}
            className={classes.textField}
            margin="normal"
            // required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="message"
            label={"Tell us about your case..."}
            multiline
            rows={4}
            // placeholder={"Tell us about your case..."}
            sx={{
              width: "100%",
              backgroundColor: "#d3d4d9",
              borderRadius: "0px",
              ".MuiInputBase-root": {
                borderRadius: "0px",
              },
            }}
            onChange={(e) => setMessage(e.target.value)}
            className={classes.textField}
            margin="normal"
            // required
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={"flex-end"}>
        <Button
          type="submit"
          variant="contained"
          // color="primary"
          // color={"#FAD461"}
          sx={{
            backgroundColor: "#3F4050",
            width: "200px",
            borderRadius: "0",
            color: "#FAD461",
            "&:hover": {
              backgroundColor: "#3F4050",
            },
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              border: "2px solid #FAD461",
              transform: "scale(0)",
              transformOrigin: "0 0",
              transition: "transform 0.5s",
            },
            "&:hover:before": {
              transform: "scale(1)",
            },
          }}
          className={classes.button}
        >
          Submit
        </Button>
      </Grid>
    </form>
  );
}
