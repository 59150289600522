import { Grid, Typography, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import ContactForm from "../components/Contact/ContactForm";
import MapIcon from "@mui/icons-material/Map";
import InstagramIcon from "@mui/icons-material/Instagram";
import ContactPageMD from "../components/Contact/ContactPageMD";
import ContactPageSM from "../components/Contact/ContactPageSM";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Paper } from "@mui/material";
const Contact = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return <ContactPageMD />;
};

export default Contact;
