import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Icon, SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const pages = ["About", "Contact", "Practice"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [sticky, setSticky] = React.useState(false);
  const navRef = React.useRef(null);

  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [scroll, setScroll] = React.useState(false);

  React.useEffect(() => {
    // Listen for scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check if the user has scrolled
    if (window.pageYOffset > 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Grid>
      <AppBar
        ref={navRef}
        id={"navbar"}
        sx={{
          // display: { xs: "none", sm: "flex" },
          backgroundColor: scroll ? "#3F4050" : "transparent",
          borderBottom: scroll ? "1px solid #3F4050" : "none",
          boxShadow: scroll ? "0px 1px 2px rgba(0,0,0,0.3)" : "none",
          // backgroundColor: "#3F4050",
          height: "4em",
          backfaceVisibility: "hidden",
          position: "fixed",
          transition: "background-color 1.0s ease-out",
        }}
      >
        <Container maxWidth="auto">
          <Toolbar
            disableGutters
            pb={10}
            sx={{ justifyContent: { xs: "center", md: "" } }}
          >
            <Box
              pl={xl ? 40 : 0}
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  href={`#${page}`}
                  sx={{
                    my: 2,
                    mr: 5,
                    color: "#FAD461",

                    display: "block",
                    border: "2px solid transparent",
                    transition: "border 0.5s",
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      border: "2px solid #FAD461",
                      transform: "scale(0)",
                      transformOrigin: "0 0",
                      transition: "transform 0.5s",
                    },
                    "&:hover:before": {
                      transform: "scale(1)",
                    },
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Typography color={"#FAD461"} fontWeight={"light"} pr={1}>
              Call Today
            </Typography>
            <a style={{ textDecoration: "none" }} href="tel:+1-505-589-7008">
              <Typography
                pr={xl ? 40 : md ? 10 : 0}
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    color: "#BB0A21",
                  },
                }}
                color={"#FAD461"}
              >
                (505) 589 - 7008
              </Typography>
            </a>
          </Toolbar>
        </Container>
      </AppBar>
    </Grid>
  );
}
export default ResponsiveAppBar;
